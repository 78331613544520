// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-template-jsx": () => import("../src/templates/blogPostTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx" */),
  "component---src-templates-blog-page-template-jsx": () => import("../src/templates/blogPageTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-page-template-jsx" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */)
}

